.Header_Dropdown_Cont
{
    position: relative;
    cursor:pointer;
}
.Header_sub_menu
{
    position: absolute;
    top: 100%;
    height: 100px;
    width: 230px;
    background-color: white;
    z-index: 100;
    display: none ;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px;
    border-radius: 18px;
    box-shadow: 0 10px 14px 0 rgba(0, 0, 0, 0.28);
}
.Header_sub_menu2
{
    position: absolute;
    display: none ;
    flex-direction: column;
    left:240px ;
    top:20px;
    justify-content: space-evenly;
    background-color: white;
    width:200px;
    z-index: 100;
    padding: 10px;
    border-radius: 18px;
    box-shadow: 0 10px 14px 0 rgba(0, 0, 0, 0.28);
}
.secureServicesMenuItem
{
    /*position: relative;*/
}
.Header_Dropdown_Cont:hover .Header_sub_menu
{
    display: flex;
}
.secureServicesMenuItem:hover .Header_sub_menu2
{
    display: flex ;
}

.Header_sub_menu span img
{
    transform: rotate(-90deg);
}
.Header_sub_menu2 span{
    margin-left: 15px;
}

.navBar{
    width:100%;
    box-shadow:0 2px 7px 0 rgba(147, 147, 147, 0.5);
    height: 100px;
    /*max-height: 100px;*/
    background-color: white;
    position: fixed;
    z-index: 999;
    transition: all ease-in 400ms;
}
.navBarContent{
    width: 90%;
    margin: auto;
    display:flex;
    align-items:center;
    justify-content: space-between;
}
.navMobile{
    /*max-height: none;*/
}
.navBarTopMargin{
    height: 100px;
}
@media screen and (max-width: 800px){
    .navBar{
        /*flex-direction: column;*/
        /*justify-content: space-evenly;*/
    }
    .navMiddle, .navButton{
        display: none !important;
    }
    .nav_close_img{
        display: block !important;
    }
    .navMobileContent{
        display:  block !important;
    }
}
.navLeft{
    display: flex;
    justify-content: center;
    align-items: center;
}
.navRight{
    display: flex;
    justify-content: center;
    align-items: center;
}
.navMiddle{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 60%;
}

.nav_close_img{
    width: 35px;
    height: 35px;
    display: none;
}

.navButton{

}
.navMobileContent{
    width: 100%;
    background-color: white;
    position: absolute;
    overflow: hidden;
    display: none;
    padding-left: 40px;
    padding-bottom: 40px;
    /*max-height: 0;*/
    /*height: 0;*/
    /*transition: max-height ease-in 1s;*/
    /*height: calc(100vh - 100px);*/
    top:100px;
    box-shadow:0 5px 7px 0 rgba(147, 147, 147, 0.5);
}

.navMobileButton{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.services_mobile_submenu{
    margin-left: 50px;
}
.services_mobile_submenu2{
    margin-left: 35px;
}
.services_mobile_submenu span{
    margin-top: 15px;
}
.navMobileButton{
    margin-top: 15px;
}
.navMobileMenuItem{
    margin-top: 10px;
}
.navMobileHeight{
    /*max-height: 100000px !important;*/
}