.map_parent_cont {
    width: 100%;
    display: flex;
    min-height: 90vh;
    height: 100px;
    margin: 20px auto;
    position: relative;
    justify-content: space-between;
    align-items: center;
}
.map_left_cont{
    width: 70%;
    height: 100%;
    margin: 10px;
    padding: 5px;
    position: relative;
    display: flex;
    align-items: flex-end;
}
.map_right_cont{
    width: 30%;
    border-radius: 32px 0 0 32px;
    padding: 20px 0;
    height: 100%;
    background-color: #32795f;
}
.mapContainer {
    width: 100%;
    height: 100%;
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 0;*/
}

@media screen and (max-width: 600px){
    .map_parent_cont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
    }
    .map_left_cont{
        width: 90%;
    }
    .map_right_cont{
        width: 90%;
    }
}