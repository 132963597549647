.orCont{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.orCont p{
  margin: 15px auto;
  font-size: 16px;
  font-family: sofiaPro;
  color: #8f92a1;
}

.orSide{
  width: 2px;
  border-radius: 12px;
  background-color: #8f92a1;
  height: 40%;
}
.css-vy2pk9-control:hover {
	border-color: #32795f !important;
}

.css-vy2pk9-control:hover {
	border-color: #32795f !important;
}

.css-vy2pk9-control {
	box-shadow: 0 0 0 1px #32795f !important;
}


@media screen and (max-width: 600px) {
  .orCont{
    flex-direction: row !important;
    width: 85% !important;
    margin: 20px auto;
  }
  .orSide{
    width: 40%;
    height: 2px;
  }
}